import React from "react";
import { graphql } from "gatsby";
import Fliesstext from "../components/Text/Fliesstext";
import DefaultLayout from "../layouts/default";
import Wrapper from "../components/Wrappers/Wrapper";
import Landing from "../components/Landing";
import PostLandingText from "../components/Text/PostLandingText";

interface TextSeitenProps {
  data: {
    cms: {
      entry: {
        title: string;
        id: number;
        siteId: number;
        metaBeschreibung: string;
      };
    };
  };
}

export const TextSeiteQuery = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId

        ... on CMS_data_textSeite_Entry {
          metaBeschreibung
          landingBild {
            url
          }
          landingTitelStartseite
          postLandingText
          text
        }
      }
    }
  }
`;

const TextSeite: React.FC<TextSeitenProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      description={data.cms.entry.metaBeschreibung}
      title={data.cms.entry.title}
    >
      {data.cms.entry.landingBild && data.cms.entry.landingBild.length > 0 ? (
        <Landing
          title={data.cms.entry.landingTitelStartseite}
          image={data.cms.entry.landingBild[0].url}
        />
      ) : (
        ""
      )}
      {data.cms.entry.postLandingText ? (
        <PostLandingText text={data.cms.entry.postLandingText} />
      ) : (
        ""
      )}
      <Wrapper>
        <Fliesstext
          text={data.cms.entry.text + "<br /><br /><br />"}
          siteId={data.cms.entry.siteId}
        />
      </Wrapper>
    </DefaultLayout>
  );
};

export default TextSeite;
